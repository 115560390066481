// !!!! - DO NOT MODIFY - !!!!
// THIS FILE IS DEPLOYED AUTOMATICALLY BY MATTv2.
//
//  Deployment info:
//    Updated at:     2023-11-24T10:36:21+00:00
//    Cause:          Manual deployment.
//    Deployment ID:  176192
//    Project:        546
//    Environment:    4936 - support/multiadd-cleanup
//    MD5:            0553d45731f00992091ed39f789cd525
//    Signature:      eyJpdiI6InBXdnlxN2lhaTFoSkxwcDB0ZVdDVFE9PSIsInZhbHVlIjoiOXplT3NwSE11OHJLd1J2a0Q4TmtLMkJNTmhNd1o2MXRnYnFXVTZqbFlBQU9oTVlPK01pZ1wvazlsT1JTaDc5eVk4RU9jd1wvOTZQVjQ5NlVnaUdDTk5NVGhwTDBUOG9mVXhOMEVoeFBtRXdxRElxYjdpWTBoelBQMmlmdnlWWHVHN01XUGdHZk9WM1FNRGJ0TGN3TFZnc2R6VEF4N2JGdWtvZTd0MGpLTUg4RUZZTlFKbDJXZUlESjl6Yjl3bWdRRlZESFwvU1wvbVBcL2Uybk1mSTBHVlNYMVpVWFNTNTJ4K2lIKzN6b2hYZWNTOGNHRlBOUlhBZDhIYW9zc08xMlFTckNic1B3TGs2YmRmb0JVSUlcL01cL1VnZkR6WTBjb1wvcXY1SjZvQ3FXUUJCYXNlZDZXRDRZejhXd0llTFo2YjN6S2EzeG5ja0d2OHJNSCtIcWtVdGpJaFNLZU5Ba2lwWlFhRHdENFY0cDhrMjl6NlAwM0Nkakt1NHlvSWQ5XC96WldSS2pUeWFxRzJlMXpwbFhVdjFxQkVPKzZkQT09IiwibWFjIjoiMmYxZjBkMDZiNTFiOTkyNjliZTgzZDQwYmFhMGI1ZDk3MTViZTVhMDg0M2Q4MDI4YWI5ODdiMTkwZDA1MWZhNyJ9

customElements.define('product-sticky-bar', class ProductStickyBar extends HTMLElement {
    constructor() {
        super();

        const addCartBtn = document.querySelector('[js-atc-btn]');
        const stickyBar = document.querySelector('.product-sticky-bar');
        const stickyBtn = document.querySelector('[js-sticky-add-to-cart-btn]');
        let addCartBtnTop = addCartBtn.getBoundingClientRect().top;
        
        window.addEventListener('scroll', function() {
            // get the current scroll position
            const scrollPos = window.scrollY || window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
            if (scrollPos > addCartBtnTop) {
                stickyBar.classList.add('show');
            } else {
                stickyBar.classList.remove('show');
            }
        });

        stickyBtn.addEventListener('click', function() {
            const clickEvent = new Event('click');
            addCartBtn.dispatchEvent(clickEvent);
        });
    }
});